/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #D6DAE1), color-stop(50%, #fff), color-stop(90%, #D6DAE1));
  background-image: linear-gradient(90deg, #D6DAE1 10%, #fff 50%, #D6DAE1 90%);
  background-size: 200% 100%;
  border-radius: 0.2rem;
  -webkit-animation: skeleton-loader 1.5s infinite linear;
          animation: skeleton-loader 1.5s infinite linear;
}

@-webkit-keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.crescent {
  position: relative;
}

.crescent.isafter::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent::before {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent.medium::before {
  width: 1rem;
  height: 1rem;
  scale: 1.08;
}

.crescent.xlarge::before {
  width: 4rem;
  height: 4rem;
  scale: 1.04;
}

@media print, screen and (min-width: 48em) {
  .crescent.large::before {
    width: 2rem;
    height: 2rem;
  }
  .crescent.medium::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .crescent.small::before {
    width: 1rem;
    height: 1rem;
  }
}
.crescent.bottom-left::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.crescent.top-left::before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.crescent.top-right::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.crescent.bottom-right::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.green-medium {
  color: #337F37;
}

.green-medium__bg {
  background: #337F37;
}

.green-light {
  color: #96DC00;
}

.green-light__bg {
  background: #96DC00;
}

.green-dark {
  color: #154734;
}

.green-dark__bg {
  background: #154734;
}

.success-300 {
  color: #3A913F;
}

.success-300__bg {
  background: #3A913F;
}

.purple-100 {
  color: #E6E6F5;
}

.purple-100__bg {
  background: #E6E6F5;
}

.purple-200 {
  color: #D2D2F3;
}

.purple-200__bg {
  background: #D2D2F3;
}

.purple-300 {
  color: #6C54AD;
}

.purple-300__bg {
  background: #6C54AD;
}

.purple-400 {
  color: #2D1446;
}

.purple-400__bg {
  background: #2D1446;
}

.pink-100 {
  color: #FAEBEB;
}

.pink-100__bg {
  background: #FAEBEB;
}

.pink-200 {
  color: #F6D4E3;
}

.pink-200__bg {
  background: #F6D4E3;
}

.pink-300 {
  color: #C83C69;
}

.pink-300__bg {
  background: #C83C69;
}

.pink-400 {
  color: #460528;
}

.pink-400__bg {
  background: #460528;
}

.teal-100 {
  color: #EBF5F0;
}

.teal-100__bg {
  background: #EBF5F0;
}

.teal-200 {
  color: #DCEBDC;
}

.teal-200__bg {
  background: #DCEBDC;
}

.teal-300 {
  color: #267B76;
}

.teal-300__bg {
  background: #267B76;
}

.teal-400 {
  color: #0F373C;
}

.teal-400__bg {
  background: #0F373C;
}

.blue-100 {
  color: #EBF2FA;
}

.blue-100__bg {
  background: #EBF2FA;
}

.blue-200 {
  color: #C3DFF4;
}

.blue-200__bg {
  background: #C3DFF4;
}

.blue-300 {
  color: #1D71AC;
}

.blue-300__bg {
  background: #1D71AC;
}

.blue-400 {
  color: #0F2D4B;
}

.blue-400__bg {
  background: #0F2D4B;
}

.yellow-100 {
  color: #FAF5CD;
}

.yellow-100__bg {
  background: #FAF5CD;
}

.yellow-200 {
  color: #F5E682;
}

.yellow-200__bg {
  background: #F5E682;
}

.yellow-300 {
  color: #F5D31A;
}

.yellow-300__bg {
  background: #F5D31A;
}

.yellow-400 {
  color: #3C3205;
}

.yellow-400__bg {
  background: #3C3205;
}

.coral-100 {
  color: #F9ECE1;
}

.coral-100__bg {
  background: #F9ECE1;
}

.coral-200 {
  color: #F7CBB1;
}

.coral-200__bg {
  background: #F7CBB1;
}

.coral-300 {
  color: #E57049;
}

.coral-300__bg {
  background: #E57049;
}

.coral-400 {
  color: #502305;
}

.coral-400__bg {
  background: #502305;
}

/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  font-weight: 700;
  gap: 0.5rem;
  font-family: "Ubuntu";
  border-radius: 0.5rem;
  background: #fff;
  cursor: pointer;
}

@media print, screen and (max-width: 47.99875em) {
  .btn {
    width: 100%;
  }
}
.btn .icon--medium {
  font-size: 1.5rem;
}

.btn .i-icon-arrow-up {
  font-size: 1.5rem;
}

.btn.default {
  padding: 0.75rem;
  font-size: 1rem;
}

.btn.default .icon {
  font-size: 1.5rem;
}

@media print, screen and (max-width: 47.99875em) {
  .btn.default.small-mobile {
    font-size: 0.875rem;
    padding: 0.5rem;
  }
  .btn.default.small-mobile .icon {
    font-size: 1.5rem;
  }
}
.btn.small {
  font-size: 0.875rem;
  padding: 0.5rem;
}

.btn.small .icon {
  font-size: 1.5rem;
}

.btn.large {
  font-size: 1.125rem;
  padding: 1.25rem 1rem;
  border-radius: 0;
}

.btn.toggle {
  width: 100%;
  color: #154734;
  background: transparent;
  z-index: 2;
}

.btn.toggle.selected {
  color: #fff;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.btn.close {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.btn.close .i-cross {
  color: #154734;
  font-size: 2rem;
}

.btn.close:hover {
  background: #EFFAF4;
}

.btn.close:focus-visible {
  border: unset;
}

.btn.close:focus {
  outline: 2px solid #267B76;
}

.btn.close:active {
  background: #207B54;
}

.btn.close:active .icon {
  color: white;
}

.btn.sticky {
  font-size: 1.125rem;
  line-height: 1.625rem;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: white;
}

@media print, screen and (max-width: 47.99875em) {
  .btn.sticky {
    height: 58px;
    padding: 1rem;
  }
}
.btn.primary {
  color: white;
  background: #154734;
  border: 1px solid #154734;
}

.btn.primary:active, .btn.primary:focus-visible, .btn.primary:focus {
  outline: 2px solid #267B76;
}

.btn.primary:disabled {
  border: none;
  cursor: not-allowed;
}

.btn.primary:disabled:hover, .btn.primary:disabled:active, .btn.primary:disabled:focus-visible, .btn.primary:disabled:focus {
  color: #6B7A8C;
  background: #EDEEF1;
  border: none;
  outline: none;
}

.btn.default.primary:hover, .btn.small.primary:hover {
  background: #196345;
}

.btn.default.primary:disabled, .btn.small.primary:disabled {
  color: #6B7A8C;
  background: #EDEEF1;
}

.btn.large.primary:hover {
  background: #196345;
}

.btn.large.primary:focus {
  background: #154734;
}

.btn.large.primary:disabled {
  color: #6B7A8C;
  background: #EDEEF1;
  border: none;
  outline: none;
}

.btn.secondary {
  border: 1px solid #154734;
  color: #154734;
}

.btn.secondary:hover {
  background: #EFFAF4;
}

.btn.secondary:focus {
  outline: 2px solid #267B76;
}

.btn.secondary:active {
  color: #207B54;
  outline: 2px solid #267B76;
}

.btn.secondary:focus-visible {
  outline: 2px solid #267B76;
}

.btn.secondary:disabled {
  color: #6B7A8C;
  border: 1px solid #D6DAE1;
  background: white;
}

.btn.secondary:disabled:hover, .btn.secondary:disabled:active, .btn.secondary:disabled:focus-visible {
  color: #6B7A8C;
  border: 1px solid #D6DAE1;
  background: white;
  outline: none;
  cursor: not-allowed;
}

.btn.ghost {
  color: #154734;
}

.btn.ghost:hover {
  background: #EFFAF4;
}

.btn.ghost:focus {
  outline: 1px solid #154734;
}

.btn.ghost:active, .btn.ghost:focus-visible {
  border: none;
  outline: 2px solid #267B76;
}

.btn.ghost:disabled {
  color: #6B7A8C;
  border: none;
  background: white;
}

.btn.ghost:disabled:hover, .btn.ghost:disabled:active, .btn.ghost:disabled:focus-visible {
  color: #6B7A8C;
  background: white;
  border: none;
  outline: none;
  cursor: not-allowed;
}

.chip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  font-weight: 400;
  color: #000000;
  padding: 0.75rem;
  border-radius: 1.5rem;
  border: 1px solid #6B7A8C;
  line-height: 1.5rem;
  cursor: pointer;
}

.chip.anchor {
  padding: 0.5rem 1rem;
  color: black;
}

.chip.select {
  color: white;
  font-weight: 700;
  border-color: #154734;
  background-color: #154734;
}

.chip.select:hover {
  background: #196345;
  border-color: #196345;
}

.chip.select:focus {
  background-color: #154734;
  outline: 2px solid #267B76;
}

@media print, screen and (max-width: 63.99875em) {
  .chip {
    font-size: 0.875rem;
  }
}
.chip:hover {
  background: #EFFAF4;
  border-color: #154734;
}

.chip:focus {
  outline: 2px solid #267B76;
}

.chip:active {
  color: #207B54;
  outline: 2px solid #267B76;
}

.chip:focus-visible {
  outline: 2px solid #267B76;
}

.chip:disabled {
  color: #6B7A8C;
  border: 1px solid #D6DAE1;
  background: white;
}

.chip:disabled:hover, .chip:disabled:active, .chip:disabled:focus-visible {
  color: #6B7A8C;
  border: 1px solid #D6DAE1;
  background: white;
  outline: none;
  cursor: not-allowed;
}

.document-to-download__container {
  padding: 2rem 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  row-gap: 2rem;
  border-radius: 1rem;
  border: 1px solid #D6DAE1;
  background: #FFFFFF;
}

@media print, screen and (max-width: 47.99875em) {
  .document-to-download__container {
    padding: 1rem;
    row-gap: 1rem;
  }
}
.document-to-download__title {
  color: #000000;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: -0.0175rem;
}

@media print, screen and (max-width: 47.99875em) {
  .document-to-download__title {
    font-size: 1.375rem;
    line-height: 1.875rem;
    letter-spacing: -0.01375rem;
  }
}
.document-to-download__list {
  margin: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 1rem;
}

.document-to-download__document.btn {
  padding: 1rem 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem;
  font-family: "Open Sans";
}

@media print, screen and (max-width: 47.99875em) {
  .document-to-download__document {
    padding: 0.5rem;
  }
}
.document-to-download__document--content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.document-to-download__document--title {
  color: #154734;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem;
  letter-spacing: -0.0225rem;
}

@media print, screen and (max-width: 47.99875em) {
  .document-to-download__document--title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: -0.00875rem;
  }
}
.document-to-download__document--size {
  color: #154734;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0.25rem;
}

.document-to-download .icon {
  font-size: 2rem;
  color: #154734;
}