/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites
 * Version 6.7.4
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.skeleton-loader {
  display: block;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(10%, #D6DAE1), color-stop(50%, #fff), color-stop(90%, #D6DAE1));
  background-image: linear-gradient(90deg, #D6DAE1 10%, #fff 50%, #D6DAE1 90%);
  background-size: 200% 100%;
  border-radius: 0.2rem;
  -webkit-animation: skeleton-loader 1.5s infinite linear;
          animation: skeleton-loader 1.5s infinite linear;
}

@-webkit-keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes skeleton-loader {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.crescent {
  position: relative;
}

.crescent.isafter::after {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent::before {
  content: "";
  display: block;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
          clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 0% 100%, 1.309% 83.779%, 5.098% 68.392%, 11.162% 54.044%, 19.294% 40.941%, 29.289% 29.289%, 40.941% 19.294%, 54.044% 11.162%, 68.392% 5.098%, 83.779% 1.309%, 100% 0%);
  background-color: #FFFFFF;
  scale: 1.08;
}

.crescent.medium::before {
  width: 1rem;
  height: 1rem;
  scale: 1.08;
}

.crescent.xlarge::before {
  width: 4rem;
  height: 4rem;
  scale: 1.04;
}

@media print, screen and (min-width: 48em) {
  .crescent.large::before {
    width: 2rem;
    height: 2rem;
  }
  .crescent.medium::before {
    width: 1.5rem;
    height: 1.5rem;
  }
  .crescent.small::before {
    width: 1rem;
    height: 1rem;
  }
}
.crescent.bottom-left::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.crescent.top-left::before {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.crescent.top-right::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.crescent.bottom-right::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.green-medium {
  color: #337F37;
}

.green-medium__bg {
  background: #337F37;
}

.green-light {
  color: #96DC00;
}

.green-light__bg {
  background: #96DC00;
}

.green-dark {
  color: #154734;
}

.green-dark__bg {
  background: #154734;
}

.success-300 {
  color: #3A913F;
}

.success-300__bg {
  background: #3A913F;
}

.purple-100 {
  color: #E6E6F5;
}

.purple-100__bg {
  background: #E6E6F5;
}

.purple-200 {
  color: #D2D2F3;
}

.purple-200__bg {
  background: #D2D2F3;
}

.purple-300 {
  color: #6C54AD;
}

.purple-300__bg {
  background: #6C54AD;
}

.purple-400 {
  color: #2D1446;
}

.purple-400__bg {
  background: #2D1446;
}

.pink-100 {
  color: #FAEBEB;
}

.pink-100__bg {
  background: #FAEBEB;
}

.pink-200 {
  color: #F6D4E3;
}

.pink-200__bg {
  background: #F6D4E3;
}

.pink-300 {
  color: #C83C69;
}

.pink-300__bg {
  background: #C83C69;
}

.pink-400 {
  color: #460528;
}

.pink-400__bg {
  background: #460528;
}

.teal-100 {
  color: #EBF5F0;
}

.teal-100__bg {
  background: #EBF5F0;
}

.teal-200 {
  color: #DCEBDC;
}

.teal-200__bg {
  background: #DCEBDC;
}

.teal-300 {
  color: #267B76;
}

.teal-300__bg {
  background: #267B76;
}

.teal-400 {
  color: #0F373C;
}

.teal-400__bg {
  background: #0F373C;
}

.blue-100 {
  color: #EBF2FA;
}

.blue-100__bg {
  background: #EBF2FA;
}

.blue-200 {
  color: #C3DFF4;
}

.blue-200__bg {
  background: #C3DFF4;
}

.blue-300 {
  color: #1D71AC;
}

.blue-300__bg {
  background: #1D71AC;
}

.blue-400 {
  color: #0F2D4B;
}

.blue-400__bg {
  background: #0F2D4B;
}

.yellow-100 {
  color: #FAF5CD;
}

.yellow-100__bg {
  background: #FAF5CD;
}

.yellow-200 {
  color: #F5E682;
}

.yellow-200__bg {
  background: #F5E682;
}

.yellow-300 {
  color: #F5D31A;
}

.yellow-300__bg {
  background: #F5D31A;
}

.yellow-400 {
  color: #3C3205;
}

.yellow-400__bg {
  background: #3C3205;
}

.coral-100 {
  color: #F9ECE1;
}

.coral-100__bg {
  background: #F9ECE1;
}

.coral-200 {
  color: #F7CBB1;
}

.coral-200__bg {
  background: #F7CBB1;
}

.coral-300 {
  color: #E57049;
}

.coral-300__bg {
  background: #E57049;
}

.coral-400 {
  color: #502305;
}

.coral-400__bg {
  background: #502305;
}

.vertical-steplist {
  background-color: #FFFFFF;
  padding-top: 2rem;
  position: relative;
  z-index: 10;
}

.vertical-steplist .green {
  color: #337F37;
}

.vertical-steplist_title {
  color: #000000;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -0.02rem;
  margin-bottom: 3.5rem;
}

@media print, screen and (max-width: 63.99875em) {
  .vertical-steplist_title {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.015rem;
    margin-bottom: 1.5rem;
  }
}
.vertical-steplist_content {
  position: relative;
  display: grid;
  grid-template-columns: 55% 45%;
  -webkit-column-gap: 2rem;
     -moz-column-gap: 2rem;
          column-gap: 2rem;
}

@media print, screen and (max-width: 63.99875em) {
  .vertical-steplist_content {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 1rem;
  }
}
.vertical-steplist_content picture {
  position: relative;
}

.vertical-steplist_img {
  position: absolute;
  bottom: -4rem;
}

@media print, screen and (max-width: 63.99875em) {
  .vertical-steplist_img {
    position: relative;
    bottom: 0;
    margin: 0 auto;
    display: block;
  }
}
.vertical-steplist_list {
  padding: 0.125rem 0 0 0;
  margin: 0 0 0 2.5rem;
  counter-reset: counter;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1.5rem;
}

.vertical-steplist_item {
  counter-increment: counter;
  position: relative;
}

.vertical-steplist_item::before {
  content: counter(counter);
  position: absolute;
  top: -0.125rem;
  left: -2.5rem;
  width: 2rem;
  height: 2rem;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: -0.01rem;
  background: #337F37;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.vertical-steplist_item:not(:last-child)::after {
  content: "";
  display: block;
  width: 0.125rem;
  height: calc(100% - 2.5rem);
  position: absolute;
  top: 2.5rem;
  left: -1.531rem;
  border-radius: 0.125rem;
  background-color: #337F37;
}

.vertical-steplist_item--title {
  margin: 0;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: -0.025rem;
  color: #000000;
}

@media print, screen and (max-width: 63.99875em) {
  .vertical-steplist_item--title {
    font-size: 1.125rem;
    letter-spacing: -0.023rem;
  }
}
.vertical-steplist_item--description, .vertical-steplist_item p {
  margin: 0;
  padding-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: #000000;
}

@media print, screen and (max-width: 63.99875em) {
  .vertical-steplist_item--description, .vertical-steplist_item p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}